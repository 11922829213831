import React, { useState } from "react";
import { Row, Col } from "antd";
import {
  Wrapper,
  ItemWrapper,
  Title,
  ListWrapper,
  ListItemWrapper,
  Text,
} from "./styles";
import Lottie from "lottie-react-web";
import animationData from "../../../assets/animations/about-us.json";
const defaultOptions = {
  loop: true,
  animationData,
};
const Index = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Wrapper>
      <Row gutter={[16, 0]}>
        <Col lg={16}>
          <ListWrapper>
            <ListItemWrapper
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              What our Partners Say
            </ListItemWrapper>
            <ListItemWrapper
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              FAQs
            </ListItemWrapper>
          </ListWrapper>

          <ItemWrapper active={activeTab === 0}>
            <Title>What our Partners Say</Title>
            <br />
            <Text>
              <b>Uche from BillsClan</b> - Nice work guys. Your partnership
              portal has a clean and impressive UI/UX. I was not expecting this
              level of attention to detail, especially from an insurance company
              in Nigeria. Kudos to the tech team @ Heirs Insurance.
            </Text>
            <Text>
              <b>Heirs Insurance Brokers</b> - We have experienced a 40% jump in
              quarterly revenue from the bonus we receive when we refer
              customers to buy insurance through InConnect.
            </Text>
          </ItemWrapper>

          <ItemWrapper active={activeTab === 1}>
            <Title>1. What is InConnect?</Title>
            <Text>
              A digital platform that allows businesses to earn money by
              reselling insurance.
            </Text>
            <Title>2. Who can sign up to InConnect?</Title>
            <Text>Small & Large Businesses, Brokers, and Aggregators.</Text>
            <Title>3. What do I need to Sign Up?</Title>
            <Text>
              Company logo, CAC certificate, Form-2, and Form-7. In addition to
              the above, brokers and aggregators will also be required to
              provide a broker's license and an aggregator's license
              respectively.
            </Text>
            <Title>4. How can my business be Connected?</Title>
            <Text>
              You can integrate our products with your solution using ours APIs.
              Also you can share or embed our product links into your platforms
              and fully manage your account from our dashboard.
            </Text>
            <Title>5. Why should your business sign up to InConnect?</Title>
            <Text>
              You earn revenue every time your customer purchases an insurance
              product.
            </Text>
          </ItemWrapper>
        </Col>
        <Col lg={8}>
          <Lottie width="100%" options={defaultOptions} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Index;
