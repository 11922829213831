import Icon, { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Upload,
  Select,
  Spin,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import SubmitButton from "../../components/Button";
import {
  DatePickerWrapper,
  InputWrapper,
  SelectText,
  SelectWrapper,
  TimePickerWrapper,
} from "../../components/Input/style";
import useClaims from "../../hooks/useClaims";
import { Title } from "../Contact/bannerStyle";
import {
  FormContentWrapper,
  ClaimFormWrapper,
  ClaimContainer,
  ClaimIcon,
  ClaimText,
  Text,
} from "../Contact/styles";
import { ReactComponent as MotorIcon } from "../../assets/images/Flexi_comprehensive.svg";
import { ReactComponent as HouseIcon } from "../../assets/images/Home_cover.svg";
import { ReactComponent as TenantIcon } from "../../assets/images/tenant_landing.svg";
import { ReactComponent as AccidentIcon } from "../../assets/images/personal_accidents.svg";
import { ReactComponent as BurglaryIcon } from "../../assets/images/burglary.svg";
import { ReactComponent as FireIcon } from "../../assets/images/fire-flame.svg";
import ClaimsSuccessForm from "./FormRating";

const ClaimsForm = () => {
  const { Option } = Select;
  const {
    disabledDate,
    error,
    handleCheckedChange,
    handleClaimChange,
    handleClaimsFormSubmit,
    handleDateChange,
    handleDescriptionChange,
    handleEmailChange,
    handleDamageFileUpload,
    handleHomeClaimChange,
    handleInsuredNameChange,
    handleLicenseFileUpload,
    handleLocationChange,
    handleMotorClaimChange,
    handleNumberChange,
    // handleOtherNumbersChange,
    handlePerilsChange,
    handlePerilClaimChange,
    handlePhoneNumberChange,
    handlePoliceFileUpload,
    handlePolicyNumberChange,
    handleRepairFileUpload,
    handleStolenItemsChange,
    handleSelectChange,
    handleTenantClaimChange,
    handleTimeChange,
    handleUploadChange,
    isDisabled,
    isLoading,
    isSuccessful,
    onDamageUpload,
    onLicenseUpload,
    onPoliceReportUpload,
    onRepairUpload,
    value,
  } = useClaims();

  return (
    <>
      {!isSuccessful ? (
        <ClaimFormWrapper>
          <FormContentWrapper>
            <Title>Heirs General Insurance Fast Claims Portal</Title>
            <Text style={{ marginBottom: "30px" }}>
              (Please note that you would be required to upload a few supporting
              documents to make your claim faster)
            </Text>
            <Form layout="vertical" onFinish={handleClaimsFormSubmit}>
              <Row gutter={[16, 0]}>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      name="insuredName"
                      label="Name of Insured"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please enter the name of the insured!",
                        },
                      ]}
                    >
                      <Row gutter={[4, 16]}>
                        <Col
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          style={{ width: "100%" }}
                        >
                          <InputWrapper
                            name="insuredFirstName"
                            placeholder="Enter first name"
                            value={value.insuredFirstName}
                            onChange={handleInsuredNameChange}
                          />
                        </Col>
                        <Col
                          sm={24}
                          md={24}
                          lg={12}
                          xl={12}
                          style={{ width: "100%" }}
                        >
                          <InputWrapper
                            name="insuredLastName"
                            placeholder="Enter surname"
                            value={value.insuredLastName}
                            onChange={handleInsuredNameChange}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    {value.insuredFirstName.length > 0 ||
                    value.insuredLastName.length > 0 ? (
                      <small>{error.insuredName}</small>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      label="Policy Number"
                      name="policyNumber"
                      type="text"
                      rules={[
                        {
                          required: true,
                          messge: "please enter policy number",
                        },
                      ]}
                      hasFeedback
                    >
                      <InputWrapper
                        name="policyNumber"
                        type="text"
                        placeholder="Enter policy number"
                        value={value.policyNumber}
                        onChange={handlePolicyNumberChange}
                      />
                    </Form.Item>
                    {error.policyNumber ? (
                      <small>{error.policyNumber}</small>
                    ) : null}
                    <Text>(Chat with us if you can't find this)</Text>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please enter the phone number",
                        },
                      ]}
                    >
                      <InputWrapper
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        value={value.phoneNumber}
                        onChange={(e) =>
                          handleNumberChange(e, handlePhoneNumberChange)
                        }
                      />
                    </Form.Item>
                    {value.phoneNumber > 0 ? (
                      <small>{error.phoneNumber}</small>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    hasFeedback
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Please enter the email address",
                      },
                    ]}
                  >
                    <InputWrapper
                      name="email"
                      placeholder="Enter email address"
                      value={value.email}
                      onChange={handleEmailChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Home/Location Address"
                name="homeAddress"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter the home/location address",
                  },
                ]}
              >
                <Input.TextArea
                  name="homeAddress"
                  placeholder="Enter home/location address"
                  value={value.homeAddress}
                  onChange={handleLocationChange}
                />
              </Form.Item>
              <Form.Item label="Type of Claim" name="size">
                <Row gutter={[16, 16]}>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="motor"
                      onClick={() => handleSelectChange("motor")}
                    >
                      <ClaimIcon>
                        <Icon component={MotorIcon} />
                      </ClaimIcon>
                      <ClaimText>Motor</ClaimText>
                    </ClaimContainer>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="home"
                      onClick={() => handleSelectChange("home")}
                    >
                      <ClaimIcon>
                        <Icon component={HouseIcon} />
                      </ClaimIcon>
                      <ClaimText>Home Insurance</ClaimText>
                    </ClaimContainer>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="tenant"
                      onClick={() => handleSelectChange("tenant")}
                    >
                      <ClaimIcon>
                        <Icon component={TenantIcon} />
                      </ClaimIcon>
                      <ClaimText>Tenant Insurance</ClaimText>
                    </ClaimContainer>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="personal accident"
                      onClick={() => handleSelectChange("personal accident")}
                    >
                      <ClaimIcon>
                        <Icon component={AccidentIcon} />
                      </ClaimIcon>
                      <ClaimText>Personal Accident Insurance</ClaimText>
                    </ClaimContainer>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="burglary"
                      onClick={() => handleSelectChange("burglary")}
                    >
                      <ClaimIcon>
                        <Icon component={BurglaryIcon} />
                      </ClaimIcon>
                      <ClaimText>Burglary</ClaimText>
                    </ClaimContainer>
                  </Col>
                  <Col sm={12} md={8} lg={6} xl={4}>
                    <ClaimContainer
                      className="claims-container"
                      id="fire & special perils"
                      onClick={() =>
                        handleSelectChange("fire & special perils")
                      }
                    >
                      <ClaimIcon>
                        <Icon component={FireIcon} />
                      </ClaimIcon>
                      <ClaimText>Fire & Special Perils</ClaimText>
                    </ClaimContainer>
                  </Col>
                </Row>
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item label="Others (please specify)" name="claimType">
                    <InputWrapper
                      name="claimType"
                      value={value.otherClaimType}
                      onChange={handleClaimChange}
                      disabled={value.claimType !== "" ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {value.claimType && value.claimType === "motor" ? (
                <>
                  <Form.Item>
                    <Form.Item
                      label="Type of Motor claim"
                      name="motorClaim"
                      rules={[
                        {
                          required: true,
                          message: "please select motor claim",
                        },
                      ]}
                    >
                      <SelectWrapper
                        mode="multiple"
                        placeholder="Select motor claim"
                        optionLabelProp="label"
                        onChange={handleMotorClaimChange}
                        value={value.motorClaims}
                      >
                        <Option value="Accident" label="Motor Accident" />
                        <Option value="Theft" label="Motor Theft" />
                        <Option value="Fire" label="Fire" />
                        <Option
                          value="Third Party Motor"
                          label="Third Party Motor"
                        />
                      </SelectWrapper>
                    </Form.Item>
                    {value.motorClaims.length > 2 ? (
                      <small>{error.motorClaims}</small>
                    ) : null}
                  </Form.Item>
                  <Row gutter={[16, 0]}>
                    <Col
                      sm={24}
                      md={24}
                      lg={12}
                      xl={12}
                      style={{ width: "100%" }}
                    >
                      <Form.Item>
                        <Form.Item
                          name="regNumber"
                          label="Vehicle Registration Number"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter the vehicle registration number",
                            },
                          ]}
                        >
                          <InputWrapper
                            name="regNumber"
                            placeholder="Enter vehicle registration number"
                            value={value.regNumber}
                            onChange={handleInsuredNameChange}
                          />
                        </Form.Item>
                        <small>{error.regNumber}</small>
                      </Form.Item>
                    </Col>
                    {/* <Col sm={24} md={24} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item
                    name="engineNumber"
                    label="Engine Number"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter the engine number",
                      },
                    ]}
                  >
                    <InputWrapper
                      name="engineNumber"
                      placeholder="Enter engine number"
                      value={value.engineNumber}
                      onChange={(e) =>
                        handleNumberChange(e, handleOtherNumbersChange)
                      }
                    />
                  </Form.Item>
                </Col> */}
                  </Row>
                </>
              ) : null}
              {value.claimType && value.claimType === "home" ? (
                <>
                  <Form.Item>
                    <Form.Item
                      label="Type of Home Insurance claim"
                      name="homeClaim"
                      rules={[
                        { required: true, message: "please select home claim" },
                      ]}
                    >
                      <SelectWrapper
                        mode="multiple"
                        placeholder="Select Home Insurance claim"
                        optionLabelProp="label"
                        onChange={handleHomeClaimChange}
                        value={value.homeClaims}
                      >
                        <Option value="Fire" label="Fire" />
                        <Option value="Burglary" label="Burglary" />
                        <Option
                          value="Accidental Damage"
                          label="Accidental Damage"
                        />
                        <Option
                          value="Personal Accident"
                          label="Personal Accident"
                        />
                        <Option value="Death" label="Death" />
                      </SelectWrapper>
                    </Form.Item>
                    {value.homeClaims.length > 2 ? (
                      <small>{error.homeClaims}</small>
                    ) : null}
                  </Form.Item>
                </>
              ) : null}
              {value.claimType && value.claimType === "tenant" ? (
                <>
                  <Form.Item>
                    <Form.Item
                      label="Type of Tenant Insurance claim"
                      name="tenantClaim"
                      rules={[
                        {
                          required: true,
                          message: "please select tenant claim",
                        },
                      ]}
                    >
                      <SelectWrapper
                        mode="multiple"
                        placeholder="Select Tenant Insurance claim"
                        optionLabelProp="label"
                        onChange={handleTenantClaimChange}
                        value={value.tenantClaims}
                      >
                        <Option value="Fire" label="Fire" />
                        <Option value="Burglary" label="Burglary" />
                        <Option
                          value="Accidental Damage"
                          label="Accidental Damage"
                        />
                        <Option
                          value="Personal Accident"
                          label="Personal Accident"
                        />
                        <Option value="Death" label="Death" />
                      </SelectWrapper>
                    </Form.Item>
                    {value.tenantClaims.length > 2 ? (
                      <small>{error.tenantClaims}</small>
                    ) : null}
                  </Form.Item>
                </>
              ) : null}
              {value.claimType &&
              value.claimType === "fire & special perils" ? (
                <>
                  <Form.Item>
                    <Form.Item
                      label="Type of Fire & Special Perils claim"
                      name="perilClaim"
                      rules={[
                        {
                          required: value.perilsClaim === "" ? true : false,
                          message: "please select peril claim",
                        },
                      ]}
                    >
                      <SelectWrapper
                        mode="multiple"
                        placeholder="Select Fire & Special Perils claim"
                        optionLabelProp="label"
                        onChange={handlePerilClaimChange}
                        value={value.perilClaims}
                      >
                        <Option value="Fire" label="Fire" />
                        <Option value="Lightning" label="Lightning" />
                        <Option
                          value="Domestic explosion"
                          label="Domestic explosion"
                        />
                        <Option value="Thunderstrike" label="Thunderstrike" />
                        <Option value="Flood" label="Flood" />
                        <Option
                          value="Impact by animal"
                          label="Impact by animal"
                        />
                        <Option
                          value="Impact by object"
                          label="Impact by object"
                        />
                      </SelectWrapper>
                    </Form.Item>
                    {value.perilClaims.length > 2 ? (
                      <small>{error.perilClaims}</small>
                    ) : null}
                  </Form.Item>
                  <Form.Item label="Others (please specify)" name="perilsClaim">
                    <InputWrapper
                      name="perilsClaim"
                      placeholder="Enter other fire & special perils claim (if any)"
                      value={value.perilsClaim}
                      onChange={handlePerilsChange}
                      disabled={value.perilClaims.length > 0 ? true : false}
                    />
                  </Form.Item>
                </>
              ) : null}
              <Form.Item
                name="incidentLocation"
                label="Incident Location"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter location of incident",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter incident location"
                  name="incidentLocation"
                  onChange={handleLocationChange}
                  value={value.incidentLocation}
                />
              </Form.Item>
              <Row gutter={[16, 0]}>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item
                    name="incidentDate"
                    label="Date of Incident"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter date of incident",
                      },
                    ]}
                  >
                    <DatePickerWrapper
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
                  <Form.Item
                    name="incidentTime"
                    label="Time of Incident"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please enter time of incident",
                      },
                    ]}
                  >
                    <TimePickerWrapper onChange={handleTimeChange} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Form.Item
                  name="description"
                  label="Brief description of Incident (not more than 250 characters)"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please enter brief description of incident",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter description"
                    name="description"
                    value={value.description}
                    onChange={handleDescriptionChange}
                    maxLength={250}
                  />
                </Form.Item>
                {value.description.length > 0 ? (
                  <small>{value.description.length}/250</small>
                ) : null}
              </Form.Item>
              {value.claimType && value.claimType === "burglary" ? (
                <Form.Item>
                  <Form.Item
                    label="List of items stolen"
                    name="stolenItems"
                    rules={[
                      {
                        required: true,
                        message: "Please enter stolen items",
                      },
                    ]}
                  >
                    <Row gutter={(16, 16)}>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>1.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemOne"
                              value={value.itemOne}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>2.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemTwo"
                              value={value.itemTwo}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>3.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemThree"
                              value={value.itemThree}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>4.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemFour"
                              value={value.itemFour}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>5.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemFive"
                              value={value.itemFive}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>6.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemSix"
                              value={value.itemSix}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>7.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemSeven"
                              value={value.itemSeven}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>8.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemEight"
                              value={value.itemEight}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>9.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemNine"
                              value={value.itemNine}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24} md={12} xl={6}>
                        <Row gutter={(4, 16)}>
                          <Col span={2}>10.</Col>
                          <Col span={22}>
                            <InputWrapper
                              placeholder="Enter item"
                              name="itemTen"
                              value={value.itemTen}
                              onChange={handleStolenItemsChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Item>
                  <small>{error.stolenItems}</small>
                </Form.Item>
              ) : null}
              <Form.Item>
                <Form.Item
                  name="damageUpload"
                  label={`Upload pictures or videos of ${
                    value.claimType === "personal accident"
                      ? "incident"
                      : "damage while showing the vehicle registration number"
                  } (you can upload more than 1 file)`}
                  valuePropName="fileList"
                  getValueFromEvent={onDamageUpload}
                  rules={[
                    {
                      required: true,
                      message: `Please upload pictures of ${
                        value.claimType === "personal accident"
                          ? "incident"
                          : "damage"
                      }`,
                    },
                  ]}
                >
                  <Dragger
                    name="logo"
                    onChange={handleUploadChange}
                    fileList={value.damageFile}
                    listType="picture"
                    multiple
                    customRequest={handleDamageFileUpload}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Attach File in PDF, JPG, PNG formats. (Max file size: 4
                      MB)
                    </p>
                  </Dragger>
                </Form.Item>
                <small>{error.damageFile}</small>
              </Form.Item>
              {value.claimType &&
              (value.claimType === "tenant" ||
                value.claimType === "burglary" ||
                (value.motorClaims && value.motorClaims.includes("Theft"))) ? (
                <Form.Item>
                  <Form.Item
                    name="policeReport"
                    label={
                      value.claimType === "motor" &&
                      value.motorClaims.includes("Theft")
                        ? "Upload an Interim Police report"
                        : "Upload a Police report"
                    }
                    valuePropName="fileList"
                    getValueFromEvent={onPoliceReportUpload}
                    rules={[
                      {
                        required: true,
                        message: "please upload police report",
                      },
                    ]}
                  >
                    <Dragger
                      name="logo"
                      onChange={handleUploadChange}
                      fileList={value.policeReport}
                      listType="picture"
                      customRequest={handlePoliceFileUpload}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">Max file size: 1 MB</p>
                    </Dragger>
                  </Form.Item>
                  <small>{error.policeReport}</small>
                </Form.Item>
              ) : null}
              {value.claimType && value.claimType === "motor" ? (
                <Form.Item>
                  <Form.Item
                    name="licenseUpload"
                    label="Upload Driver's License"
                    valuePropName="fileList"
                    getValueFromEvent={onLicenseUpload}
                    rules={[
                      {
                        required: true,
                        message: "please upload driver's license",
                      },
                    ]}
                  >
                    <Dragger
                      name="logo"
                      onChange={handleUploadChange}
                      fileList={value.licenseFile}
                      multiple
                      listType="picture"
                      customRequest={handleLicenseFileUpload}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Attach File (Max size: 4 MB)
                      </p>
                    </Dragger>
                  </Form.Item>
                  <small>{error.licenseFile}</small>
                </Form.Item>
              ) : null}
              <Row gutter={[16, 0]}>
                <Col span={24} id="repairUpload">
                  <Form.Item>
                    <Form.Item
                      name="repairUpload"
                      label={
                        value.claimType === "personal accident"
                          ? "Upload medical bills"
                          : "Upload an estimate of repair"
                      }
                      valuePropName="fileList"
                      getValueFromEvent={onRepairUpload}
                      extra="Attach File (Max size: 4 MB)"
                    >
                      <Upload
                        name="logo"
                        onChange={handleUploadChange}
                        fileList={value.repairFile}
                        listType="picture"
                        multiple
                        customRequest={handleRepairFileUpload}
                      >
                        <Button icon={<UploadOutlined />}>
                          Click to upload
                        </Button>
                      </Upload>
                    </Form.Item>
                    <small>{error.repairFile}</small>
                  </Form.Item>
                </Col>
                {value.claimType !== "personal" ? (
                  <>
                    <Col span={12}>
                      <SelectText>OR</SelectText>
                    </Col>
                    <Col span={24} id="approvedEstimate">
                      <Form.Item
                        name="approvedEstimate"
                        label="Click yes to use Heirs General Insurance approved estimate"
                      >
                        <Checkbox
                          checked={value.approvedEstimate}
                          onChange={handleCheckedChange}
                        >
                          Yes
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                ) : null}
              </Row>
              <Form.Item name="trueClaims">
                <Checkbox
                  checked={value.trueClaims}
                  onChange={handleCheckedChange}
                >
                  Confirm that this claim is valid. If claim is found to be
                  false or partly fraudulent, you will forfeit the entire
                  monetary value of your claim and be liable to legal action.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Form.Item>
                  {isLoading ? (
                    <SubmitButton disabled style={{ width: "100%" }}>
                      <Spin size="small" />
                    </SubmitButton>
                  ) : (
                    <SubmitButton
                      disabled={isDisabled}
                      style={{ width: "100%" }}
                      htmlType="submit"
                    >
                      Submit
                    </SubmitButton>
                  )}
                </Form.Item>
                <small>{error.submitError}</small>
              </Form.Item>
            </Form>
          </FormContentWrapper>
        </ClaimFormWrapper>
      ) : (
        <ClaimsSuccessForm
          claimType={value.claimType}
          insuredName={`${value.insuredFirstName} ${value.insuredLastName}`}
        />
      )}
    </>
  );
};

export default ClaimsForm;
