import React from "react";
import { Row, Col } from "antd";
import { Wrapper, Content, ContentItem, Title, Text } from "./styles";
import Section from "../Section";
import leadership from "../../assets/images/Tony.png";
import adaobi from "../../assets/images/adaobi.png";
import niyi from "../../assets/images/niyi.png";
import dan from "../../assets/images/Dan.png";
import hajia from "../../assets/images/Hajia.png";
import henry from "../../assets/images/Henry.png";
import japhet from "../../assets/images/Japhet.png";
import peters from "../../assets/images/Peters.png";
import mohammed from "../../assets/images/Mohammed.png";
import misbahu from "../../assets/images/Misbahu.png";

const Index = () => {
  return (
    <Wrapper>
      <Content>
        <Section title="Leadership" filled>
          <Row gutter={50}>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={leadership} alt="leader" />
                <Title> Tony O. Elumelu CFR</Title>
                <Text> Chairman</Text>
              </ContentItem>
            </Col>
            {/* <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={adaobi} alt="leader" />
                <Title> Dr. Adaobi Nwakuche</Title>
                <Text> Managing Director/CEO</Text>
              </ContentItem>
            </Col> */}
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={japhet} alt="leader" />
                <Title> Dr. Japhet Duru</Title>
                <Text> Ag. MD/CEO & ED, Technical</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={henry} alt="leader" />
                <Title> Henry Egbiki</Title>
                <Text> Independent Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={hajia} alt="leader" />
                <Title> Salma Yusuf Mohammed</Title>
                <Text> Independent Non- Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={dan} alt="leader" />
                <Title> Dan Okeke</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={peters} alt="leader" />
                <Title> Peter Ashade</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={niyi} alt="leader" />
                <Title> Niyi Onifade</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={misbahu} alt="leader" />
                <Title> Misbahu Umar Yola</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
            <Col lg={6} md={12} xs={24}>
              <ContentItem>
                <img src={mohammed} alt="leader" />
                <Title> Mohammed Idris</Title>
                <Text> Non-Executive Director</Text>
              </ContentItem>
            </Col>
          </Row>
        </Section>
      </Content>
    </Wrapper>
  );
};

export default Index;
