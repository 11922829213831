import React from "react";
import { Menu, Grid } from "antd";
import Icon, { CaretDownOutlined } from "@ant-design/icons";
import { ReactComponent as CarIcon } from "../../../../assets/images/car.svg";
import { ReactComponent as HearseIcon } from "../../../../assets/images/hearse.svg";
import { ReactComponent as ComprehensiveIcon } from "../../../../assets/images/Comprehensive.svg";
import { ReactComponent as HerMotorIcon } from "../../../../assets/images/HerMotor.svg";
import { ReactComponent as BurglaryIcon } from "../../../../assets/images/burglary.svg";
import { ReactComponent as BikerIcon } from "../../../../assets/images/motorbike.svg";
import { ReactComponent as LandlordIcon } from "../../../../assets/images/landlord.svg";
import { ReactComponent as TenantIcon } from "../../../../assets/images/tenant.svg";
// import { ReactComponent as PhoneIcon } from "../../../../assets/images/smartphone.svg";
import { ReactComponent as AccidentIcon } from "../../../../assets/images/accident.svg";
import { ReactComponent as BlogIcon } from "../../../../assets/images/blog.svg";
import { ReactComponent as NewsIcon } from "../../../../assets/images/news.svg";
import { ReactComponent as ShipmentIcon } from "../../../../assets/images/shipment.svg";
import { ReactComponent as ShipIcon } from "../../../../assets/images/ship.svg";
// import { ReactComponent as FileIcon } from "../../../../assets/images/file.svg";
import { ReactComponent as FireIcon } from "../../../../assets/images/fire-insurance.svg";
import { ReactComponent as JoinOurTeamIcon } from "../../../../assets/images/blog.svg";
import { ReactComponent as WhoWeAreIcon } from "../../../../assets/images/news.svg";
import { ReactComponent as MobileIcon } from "../../../../assets/images/mobile.svg";
import { ReactComponent as FinanceIcon } from "../../../../assets/images/finance.svg";
import { ReactComponent as CorporateIcon } from "../../../../assets/images/corporate.svg";
import { ReactComponent as BannerIcon } from "../../../../assets/images/banner.svg";
import { Link } from "react-router-dom";
import { IconWrapper } from "../style";
import { Mixpanel } from "../../../../Mixpanel";

const SubMenu = Menu.SubMenu;

const { useBreakpoint } = Grid;

const LeftMenu = () => {
  const menus = [
    {
      key: "home",
      path: "/home",
      name: "Home",
    },
    {
      key: "products",
      path: "/product",
      name: "Products",
      subMenus: [
        {
          key: "product",
          path: "/third-party",
          name: "Third Party Motor",
          icon: CarIcon,
        },
        /* {
          key: "flexi",
          path: "/flexi-motor",
          name: "Flexi Motor",
          icon: HearseIcon,
        }, */
        {
          key: "comprehensive",
          path: "/comprehensive-motor",
          name: "Comprehensive Motor",
          icon: ComprehensiveIcon,
        },
        {
          key: "her-motor",
          path: "/her-motor",
          name: "Her Motor Insurance",
          icon: HerMotorIcon,
        },
        {
          key: "burglary",
          path: "/burglary",
          name: "Burglary Insurance",
          icon: BurglaryIcon,
        },
        /* {
          key: "bikers",
          path: "/bikers",
          name: "Bikers",
          icon: BikerIcon,
        }, */
        {
          key: "Homeowner Insurance",
          path: "/home-protect",
          name: "Homeowner Insurance",
          icon: LandlordIcon,
        },
        {
          key: "tenant",
          path: "/tenants",
          name: "Tenants",
          icon: TenantIcon,
        },
        // {
        //   key: "mobileItems",
        //   path: "/product",
        //   name: "Mobile Items",
        //   icon: PhoneIcon,
        // },
        {
          key: "personalAccident",
          path: "/personal-accident",
          name: "Personal Accident",
          icon: AccidentIcon,
        },
        {
          key: "goodsInTransit",
          path: "/goods-in-transit",
          name: "Goods-In-Transit",
          icon: ShipmentIcon,
        },
        {
          key: "hull",
          path: "/hull",
          name: "Marine Hull Insurance",
          icon: ShipIcon,
        },
        {
          key: "cargo",
          path: "/cargo",
          name: "Marine Cargo Insurance",
          icon: ShipIcon,
        },
        // {
        //   key: "Other",
        //   path: "HeirsDashboardUrl/",
        //   name: "Other",
        //   icon: FileIcon,
        // },
        {
          key: "fire",
          path: "/fire",
          name: "Fire & Special Perils",
          icon: FireIcon,
        },
      ],
    },
    {
      key: "about",
      path: "/about",
      name: "About Us",
      subMenus: [
        // {
        //   key: "career",
        //   name: "Join our Team",
        //   icon: BlogIcon,
        //   path: "/career",
        // },
        {
          key: "who",
          name: "Who we are",
          icon: WhoWeAreIcon,
          path: "/who-we-are",
        },
        {
          key: "digital-channels",
          name: "Digital Channels",
          icon: MobileIcon,
          path: "/digital-channels",
        },
        {
          key: "careers",
          name: "Careers",
          icon: JoinOurTeamIcon,
          path: "/career",
        },
      ],
    },
    // {
    //   key: "answers",
    //   path: "/answers",
    //   name: "Get Answers",
    // },
    {
      key: "getAnswers",
      path: "/media",
      name: "Media",
      subMenus: [
        {
          key: "blog",
          name: "Blog",
          icon: BlogIcon,
          path: "/blog",
        },
        {
          key: "news",
          name: "News",
          icon: NewsIcon,
          path: "/news",
        },
        // {
        //   key: "financial-statements",
        //   name: "Financial statements",
        //   icon: FinanceIcon,
        //   path: "/financial-statements",
        // },
        {
          key: "corporate-governance",
          name: "Corporate Governance",
          icon: CorporateIcon,
          path: "/corporate-governance",
        },
        {
          key: "faq",
          name: "FAQs",
          icon: NewsIcon,
          path: "/faq",
        },
        // {
        //   key: "product-flyers",
        //   name: "Product Flyers",
        //   icon: BannerIcon,
        //   path: "/product-flyers",
        // },
      ],
    },
    {
      key: "contact",
      path: "/contact",
      name: "Contact Us",
    },
    /* {
      key: "partners",
      path: "/partnership-portal",
      name: "Partnership",
    }, */
    {
      key: "partnership",
      path: "/partnership",
      name: "Partnerships",
      subMenus: [
        {
          key: "portal",
          name: "Partnership Portal",
          icon: BlogIcon,
          // path: "/InConnect",
          path: "/partnership-portal",
        },
        {
          key: "bancassurance",
          name: "Bancassurance",
          icon: TenantIcon,
          path: "/bancassurance",
        },
        /* {
          key: "tef",
          name: "Tony Elumelu Foundation",
          icon: BlogIcon,
          path: "/TEF",
        }, */
      ],
    },
  ];

  const { lg } = useBreakpoint();

  const renderSubMenuTitle = (name) => {
    return (
      <span>
        {name} <CaretDownOutlined />
      </span>
    );
  };

  return (
    <Menu mode={lg ? "horizontal" : "inline"}>
      {menus.map((menu) =>
        menu.subMenus ? (
          <SubMenu
            key={menu.key}
            title={renderSubMenuTitle(menu.name)}
            id={menu.key}
          >
            {menu.subMenus.map((subMenu) => (
              <Menu.Item
                key={subMenu.key}
                className={`ant-col ${
                  menu.subMenus.length >= 12 ? "ant-col-lg-8" : "ant-col-lg-12"
                }`}
              >
                <a href={subMenu.path} onClick={Mixpanel.track(subMenu.name)}>
                  <IconWrapper>
                    <Icon
                      component={subMenu.icon}
                      style={{ fontSize: "20px" }}
                    />
                  </IconWrapper>
                  {subMenu.name}
                </a>
              </Menu.Item>
            ))}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            <Link to={menu.path}>{menu.name}</Link>
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

export default LeftMenu;
