import React from "react";
import { Row, Col } from "antd";
import {
  DetailsContentWrapper,
  DetailsWrapper,
  Title,
  Text,
  PhoneImageWrapper,
  LeftDetailsContentWrapper,
  RightDetailsContentWrapper,
  DetailsRowWrapper,
} from "./styles";
import { SubTitle } from "../Contact/bannerStyle";
import contactPhone from "../../assets/images/contact-phone.png";
// import { Link } from "../../components/Navigation/Footer/styles";
import appleStore from "../../assets/images/appstore.png";
import googlePlay from "../../assets/images/playstore.png";

const Form = () => {
  return (
    <DetailsWrapper>
      <DetailsContentWrapper>
        <DetailsRowWrapper>
          <Row justify="center" align="middle" gutter={0}>
            {/* <Col
              sm={8}
              xs={12}
              style={{ display: "inline-flex", alignSelf: "stretch" }}
            >
              <LeftDetailsContentWrapper>
                <Title>Coming Soon...</Title>
                <Text>
                  <h3>All your savings and protection needs in one place.</h3>
                  <br />
                </Text>
              </LeftDetailsContentWrapper>
            </Col> */}
            <Col sm={8} xs={0} style={{ marginLeft: "40vh" }}>
              <PhoneImageWrapper>
                <img src={contactPhone} alt="contact" />
              </PhoneImageWrapper>
            </Col>
            <Col
              sm={8}
              xs={12}
              style={{ display: "inline-flex", alignSelf: "stretch" }}
            >
              <RightDetailsContentWrapper>
                <Title style={{ textAlign: "left" }}>Coming Soon</Title>
                <div>
                  <img src={appleStore} alt={"apple"} />
                  <img src={googlePlay} alt={"google"} />
                </div>
              </RightDetailsContentWrapper>
            </Col>
          </Row>
        </DetailsRowWrapper>
      </DetailsContentWrapper>
    </DetailsWrapper>
  );
};

export default Form;
