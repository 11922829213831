import { withWrapper, withoutFooter } from "../HOC/AppWrapper";
import * as Screen from "../../screens";

const routes = [
  {
    path: "/",
    // component: withoutFooter(Screen.Start),
    component: withWrapper(Screen.Home),
  },
  {
    path: "/home",
    component: withWrapper(Screen.Home),
  },
  /* {
    path: "/bikers",
    component: withWrapper(Screen.Product),
  }, */
  {
    path: "/third-party",
    component: withWrapper(Screen.ThirdParty),
  },
  {
    path: "/flexi-x",
    component: withWrapper(Screen.Flexi),
  },
  {
    path: "/comprehensive-motor",
    component: withWrapper(Screen.Comprehensive),
  },
  {
    path: "/her-motor",
    component: withWrapper(Screen.HerMotor),
  },
  {
    path: "/burglary",
    component: withWrapper(Screen.Burglary),
  },
  {
    path: "/personal-accident",
    component: withWrapper(Screen.PersonalAccident),
  },
  {
    path: "/tenants",
    component: withWrapper(Screen.Tenants),
  },
  {
    path: "/fire",
    component: withWrapper(Screen.Fire),
  },
  {
    path: "/home-protect",
    component: withWrapper(Screen.HomeProtect),
  },
  {
    path: "/cargo",
    component: withWrapper(Screen.Cargo),
  },
  {
    path: "/hull",
    component: withWrapper(Screen.Hull),
  },
  {
    path: "/goods-in-transit",
    component: withWrapper(Screen.GoodsInTransit),
  },
  {
    path: "/claims",
    component: withWrapper(Screen.FastClaims),
    // component: withWrapper(Screen.Claims),
  },
  {
    path: "/fast-claims",
    component: withWrapper(Screen.FastClaims),
  },
  {
    path: "/who-we-are",
    component: withWrapper(Screen.About),
  },
  {
    path: "/career",
    component: withWrapper(Screen.Career),
  },
  {
    path: "/digital-channels",
    component: withWrapper(Screen.DigitalChannels),
  },
  {
    path: "/answers",
    component: withWrapper(Screen.GetAnswer),
  },
  {
    path: "/wp-media",
    component: withWrapper(Screen.Media),
  },
  {
    path: "/wp-media/:id",
    component: withWrapper(Screen.MediaPost),
  },
  {
    path: "/blog",
    component: withWrapper(Screen.WPMedia),
  },
  {
    path: "/news",
    component: withWrapper(Screen.News),
  },
  {
    path: "/blog/:id",
    component: withWrapper(Screen.WPMediaPost),
  },
  {
    path: "/news/:id",
    component: withWrapper(Screen.NewsPost),
  },
  {
    path: "/financial-statements",
    component: withWrapper(Screen.FinancialStatements),
  },
  {
    path: "/corporate-governance",
    component: withWrapper(Screen.CorporateGovernance),
  },
  {
    path: "/product-flyers",
    component: withWrapper(Screen.ProductFlyers),
  },
  {
    path: "/privacy",
    component: withWrapper(Screen.Privacy),
  },
  {
    path: "/terms",
    component: withWrapper(Screen.Terms),
  },
  {
    path: "/contact",
    component: withWrapper(Screen.Contact),
  },
  {
    path: "/complaint",
    component: withWrapper(Screen.Complaint),
  },
  {
    path: "/flexi-campaign",
    component: withWrapper(Screen.FlexiCampaign),
  },
  {
    path: "/her-motor-campaign",
    component: withWrapper(Screen.HerMotorCampaign),
  },
  {
    path: "/tenant-protect-campaign",
    component: withWrapper(Screen.TenantProtectCampaign),
  },
  {
    path: "/partnership-portal",
    component: withWrapper(Screen.PartnershipPortal),
  },
  {
    path: "/TEF",
    component: withWrapper(Screen.TEFCampaign),
  },
  {
    path: "/bancassurance",
    component: withWrapper(Screen.Bancassurance),
  },
  {
    path: "/whistleblowing",
    component: withWrapper(Screen.WhistleBlowing),
  },
  {
    path: "/bancassurance-products",
    component: withWrapper(Screen.BancassuranceProducts),
  },
  {
    path: "/faq",
    component: withWrapper(Screen.Faq),
  },
  {
    path: "/inconnect",
    component: withWrapper(Screen.Connect),
  },
];

export { routes };
