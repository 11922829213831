import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "../../Mixpanel";
import burglary from "../../assets/images/heirs-burglary-02.png";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 450) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    // localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    // window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="burglary">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={burglary} alt="burglary" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Burglary Insurance</Title>
                  {/* <a
                    onClick={Mixpanel.track("Burglary Insurance Buy")}
                    href={`${HeirsDashboardUrl}/motor-policy-type`}
                  >
                    Buy Now
                  </a> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Risk (s)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    In the event of burglary, attempted break in, and similar
                    risks...
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <List>
                      <li>We replace the valuable items in your building.</li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Photographs of Subject Matter.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid Means of I.D- Driver license, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Certificate of incorporation (for company).</li>
                      <li>
                        Proof of residence - Nepa or Lawma bill, Front page of
                        the customer's address (same address as indicated on the
                        form or portal), Local government levy.
                      </li>
                      <li>Valuation Report (Building).</li>
                      <li>Inventory of contents with values.</li>
                      <li>Purchase receipt.</li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td colspan="2" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      href="/contact"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "5px 5px 0px",
                        borderRadius: "5px",
                        width: "150px",
                        height: "70px",
                      }}
                    >
                      Contact Us <br />
                      to purchase
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
