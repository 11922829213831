/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Col, Modal, Form } from "antd";
import Icon, { WindowsFilled } from "@ant-design/icons";
import {
  PlanWrapper,
  Title,
  Text,
  LinksWrapper,
  LinksWrapperGrey,
  FormWrapper,
  List,
} from "../style";
import { Mixpanel } from "./../../../Mixpanel";
import FormInput from "../../../components/Input";
import axios from "axios";
import { HeirsEmailUrl } from "../../../utils/constants";
import { DatePickerWrapper } from "../../../components/Input/style";
import ReactDOM from "react-dom";

const Plan = ({ title, icon, price, url, mixpanel, quote, inx, req }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });

  const handleClick = () => {
    Mixpanel.track(mixpanel + " - Buy Now");
    window.location.pathname = url;
  };

  const handleClick_Q = () => {
    Mixpanel.track(mixpanel + " - Get Quote");
  };

  const handleClick_H = () => {
    Mixpanel.track(mixpanel);
  };

  const handleRemind = () => {
    Mixpanel.track("Send Message");
    const data = {
      firstName: "",
      lastName: "",
      email: email,
      phoneNumber: "",
      destination: [
        "wecare@heirsgeneralinsurance.com",
        "nimiweremi.selekere@heirslifeassurance.com",
      ],
      message: date,
      plan: title,
    };
    axios({
      method: "POST",
      url: `${HeirsEmailUrl}/email/others`,
      data: data,
    })
      .then((response) => {
        if (response.status === 200) {
          handleServerResponse(true, response.data.message);
          // setTimeout(() => {
          //   history.push("/flexi-motor");
          // }, 5000);
        } else {
          handleServerResponse(false, response.data.message);
        }
      })
      .catch((error) => {
        handleServerResponse(false, "An internal error occured");
      });
  };

  const handleDateChange = (e) => {
    setDate(`Remind me on ${e._d}`);
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      Modal.success({
        content: msg,
      });
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
      );
      Array.from(document.querySelectorAll("textarea")).forEach(
        (textarea) => (textarea.value = "")
      );
      // setIsDisabled(false);
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const showQuote = () => {
    !inx ? setModalVisible(true) : window.open(quote);
  };

  const showText = !inx ? "BUY LATER" : "GET QUOTE";
  const dtext = !inx ? "none" : "";
  const mim = !inx ? "minimum" : "";
  const l1 = req == 1 ? "" : "none";
  const l2 = req == 2 ? "" : "none";
  const l3 = req == 3 ? "" : "none";
  const l4 = req == 4 ? "" : "none";
  const l5 = req == 5 ? "" : "none";
  const l6 = req == 6 ? "" : "none";
  const l7 = req == 7 ? "" : "none";
  const l8 = req == 8 ? "" : "none";
  quote = quote == "" ? url : quote;

  return (
    <>
      <Col xs={12} sm={8} md={6} lg={6}>
        <PlanWrapper>
          <a href={url} onClick={handleClick_H}>
            <Icon component={icon} />
          </a>
          <Title>{title}</Title>
          <Text>
            {price} {mim}
          </Text>
          <LinksWrapper>
            <a onClick={showModal}>Requirements</a>
          </LinksWrapper>
          <LinksWrapper onClick={handleClick}>
            <a href={quote}>Buy Now</a>
          </LinksWrapper>
          <LinksWrapperGrey style={{ display: dtext }} onClick={handleClick_Q}>
            {/* <a onClick={showModal}>Buy Later</a> */}
            <a onClick={showQuote}>{showText}</a>
          </LinksWrapperGrey>
        </PlanWrapper>
      </Col>
      <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Remind me"
        onOk={() => handleRemind(title)}
        footer={null}
      >
        <Col>
          <h1>Documents Required</h1>
        </Col>
        <FormWrapper>
          <Col span={24} style={{ padding: 0 }}>
            <List style={{ display: l1 }}>
              <li>Duly Filled Proposal/KYC Form.</li>
              <li>Proof of Ownership.</li>
              <li>Evidence of Payment.</li>
              <li>Copy of the Vehicle License/Proof of Ownership.</li>
              <li>Photographs of subject matter.</li>
              <li>
                Valid means of ID - Driver License, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of Residence - NEPA or LAWMA Bill, Front page of the
                customer's address (same address as indicated on the form or
                portal), Local Government Levy.
              </li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l2 }}>
              <li>Duly Filled Proposal/KYC Form.</li>
              <li>Photographs of Subject Matter.</li>
              <li>Evidence of Payment.</li>
              <li>
                Valid Means of I.D- Driver license, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>Certificate of incorporation (for company).</li>
              <li>
                Proof of residence - Nepa or Lawma bill, Front page of the
                customer's address (same address as indicated on the form or
                portal), Local government levy.
              </li>
              <li>Valuation Report (Building).</li>
              <li>Inventory of contents with values</li>
              <li>Purchase receipt</li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l4 }}>
              <li>Duly filled proposal form.</li>
              <li>Evidence of Payment.</li>
              <li>
                Valid means of ID - Driver license, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of Residence - Nepa or Lawma bill, Front page of the
                customer's address (same address as indicated on the form or
                portal), Local government levy.
              </li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l5 }}>
              <li>Duly Filled Proposal Form.</li>
              <li>Evidence of Payment.</li>
              <li>Certificate of Incorporation (for company).</li>
              <li>Proforma Invoice.</li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l6 }}>
              <li>Duly Filled Proposal Form.</li>
              <li>Evidence of Payment.</li>
              <li>Valid means of ID.</li>
              <li>Certificate of Incorporation (for company).</li>
              <li>Waybill Invoice (where applicable).</li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l7 }}>
              <li>
                Valid means of ID - Driver License, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of Residence - NEPA or LAWMA Bill, Front page of the
                customer's address (same address as indicated on the form or
                portal), Local Government Levy.
              </li>
              <br />
              <a href={url} style={{ padding: "8px", margin: "20px 0px" }}>
                Read More...
              </a>
            </List>
            <List style={{ display: l8 }}>
              <li>Proof of Ownership.</li>
              <li>Copy of the Vehicle License.</li>
              <li>Photographs of subject matter.</li>
              <li>
                Valid means of ID - Driver License, Voter's card, NIMC, National
                ID, International Passport.
              </li>
              <li>
                Proof of Residence - NEPA or LAWMA Bill, Front page of the
                customer's address (same address as indicated on the form or
                portal), Local Government Levy.
              </li>
              <br />
              <a href={url} style={{ padding: "8px" }}>
                Read More...
              </a>
            </List>
          </Col>
        </FormWrapper>
      </Modal>
      {/* <Modal
        visible={modalVisible}
        onCancel={handleCancel}
        okText="Remind me"
        onOk={() => handleRemind(title)}
      >
        <Col>
          <h1>Remind me to buy {title}</h1>
        </Col>
        <FormWrapper>
          <Col span={24} style={{ padding: 0 }}>
            <label htmlFor="email_address">Enter your email address</label>
            <FormInput
              type="email"
              name="email_address"
              placeholder="Email Address"
              onChange={handleEmailChange}
            />
          </Col>
          <Col sm={24} md={12} lg={12} xl={12} style={{ width: "100%" }}>
            <Form.Item
              name="incidentDate"
              label="Reminder Date"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter date of reminder",
                },
              ]}
            >
              <DatePickerWrapper onChange={handleDateChange} />
            </Form.Item>
          </Col>
        </FormWrapper>
        {status.info.error && (
          <div className="error">Error: {status.info.msg}</div>
        )}
        {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      </Modal> */}
    </>
  );
};

export default Plan;
