import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
// import { HeirsDashboardUrl } from "../../utils/constants";
// import { Mixpanel } from "../../Mixpanel";
import burglary from "../../assets/images/Icon_Homeprotect.png";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    // localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    // window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="goods">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <img src={burglary} alt="burglary" />
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Single Transit Restricted Cover</Title>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title> Single Transit all-risks cover</Title>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Open Restricted cover</Title>
                </th>
                <th className="ant-table-cell" style={{ width: "20%" }}>
                  <Title>Open all-risks cover</Title>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the value of goods in transit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the value of goods in transit</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the estimated annual cost of goods.</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    Based on the estimated annual cost of goods and the number
                    of transits per year.
                  </Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Business owners using owned vehicles to transport goods
                    </li>
                    <li>Individuals relocating from one building to another</li>
                    <li>For one-off transit</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Business owners using either owned or hired vehicles to
                      transport goods
                    </li>
                    <li>Individuals relocating from one building to another</li>
                    <li>For one-off transit</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Business owners using owned vehicles to transport goods
                    </li>
                    <li>For regular transit</li>
                  </List>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Business owners using either owned or hired vehicles to
                      transport goods
                    </li>
                    <li>For regular transit</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="5">
                  <Text highlight>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Loss or damage to goods in the case of overturning or
                    collision of the conveying vehicle.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Loss or damage to goods in the case of theft, violence or
                    other threats.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Location</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Specified To and Fro locations</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Specified To and Fro locations</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Nationwide</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Nationwide</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="4">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal Form.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid Means of ID - Driver license, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Certificate of Incorporation (for company).</li>
                      <li>Waybill Invoice (where applicable).</li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td colspan="5" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      href="/contact"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "5px 5px 0px",
                        borderRadius: "5px",
                        width: "150px",
                        height: "70px",
                      }}
                    >
                      Contact Us <br />
                      to purchase
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
