import React, { useEffect, useState } from "react";
import { List, Spin, Avatar, Card, Skeleton, Switch, Button } from "antd";
import {
  AnswerItemWrapper,
  AnswersWrapper,
  ImageContainer,
  Text,
  Title,
} from "./styles";
import { dateFormat } from "../../utils/dateFormatter";
import { ContentLoader } from "../MediaPost/styles";
import CallBack from "../../components/CallBack";

const FinancialStatements = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPosts = (pageNum) => {
    setPosts([
      /* {
        ID: 1,
        title:
          "HLA - 2021 Corporate Governance Evaluation Statement for Annual Report",
        description:
          "HLA - 2021 Corporate Governance Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc",
      },
      {
        ID: 2,
        title: "HLA - 2021 Board Evaluation Statement for Annual Report",
        description:
          "HLA  - 2021 Board Evaluation Statement for Annual Report. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/EbnEboMVditAq-h1fv0h5lEBpy67OzE4Sq_b4pXkQM-ZMA?e=HWqIxX",
      }, */
      {
        ID: 1,
        title: "Stakeholders Management and Communications Policy",
        description:
          "Stakeholders Management and Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/ESeaatpScSlNjyJlWXafE_8B0SsSRI3m7Sv-xMMhP4yvUA?e=YWe4Ul",
      },
      {
        ID: 2,
        title: "Corporate Communications Policy",
        description:
          "Corporate Communications Policy. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EU3w6cNQ_mROpryX-1__5PsByBHfB6NdNy4lzu3Q-IIi4w?e=mYRbJ0",
      },
      {
        ID: 3,
        title: "13 Months Financial Statement Reports Ending December 2021",
        description:
          "13 months financial statement reports ending december, 2021. Click the button to read the full details of the report",
        image: "https://via.placeholder.com/350x150",
        url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/PublicFolder/EY1MT08R1DtFn0jCuf-JDygBPqPDRax7bq1eCHMs6ONu4A?e=bITdM9",
      },
      // {
      //   ID: 3,
      //   title: "HLA - Stakeholders Management and Communications Policy",
      //   description:
      //     "HLA - Stakeholders Management and Communications Policy. Click the button to read the full details of the report",
      //   image: "https://via.placeholder.com/350x150",
      //   url: "https://heirsinsurancelimited.sharepoint.com/:b:/s/Repository/ESWuOCXblU5KtNJ2Atr-RzIBdsYshvF8TUO_HZ63-15GZg?e=gKpgAc",
      // },
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <>
      <AnswersWrapper>
        {!isLoading ? (
          <>
            <List
              // pagination={{
              //   position: "bottom",
              //   total: totalPosts,
              //   pageSize: pageSize,
              //   current: currentPage,
              //   onChange: (page) => {
              //     handlePageChange(page);
              //   },
              // }}
              grid={{
                gutter: 64,
                column: 2,
              }}
              dataSource={posts}
              renderItem={(item) => (
                <List.Item key={item.ID}>
                  <Card
                    style={{ width: 500, marginTop: 16 }}
                    actions={[
                      <Button
                        onClick={() => {
                          window.open(item.url);
                        }}
                      >
                        View Report
                      </Button>,
                    ]}
                  >
                    <Card.Meta
                      title={item.title}
                      description={item.description}
                    />
                  </Card>
                </List.Item>
              )}
            />
          </>
        ) : (
          <ContentLoader>
            <Spin size="large" />
          </ContentLoader>
        )}
      </AnswersWrapper>
      {/* <CallBack /> */}
    </>
  );
};

export default FinancialStatements;
