import React from "react";
import { Row, Col } from "antd";
import {
  Container,
  Wrapper,
  Title,
  Text,
  Link,
  // DownloadItemContent,
  // DownloadItemWrapper,
  DownloadWrapper,
  SocialMediaWrapper,
  // IconWrapper,
  // SubTitle,
  CompliantWrapper,
} from "./styles";

// import Icon, { AppleFilled } from "@ant-design/icons";
// import { ReactComponent as PlayIcon } from "../../../assets/images/play.svg";

import appleStore from "../../../assets/images/appstore.png";
import googlePlay from "../../../assets/images/playstore.png";
import FacebookIcon from "../../../assets/images/heirs-icons-facebook.svg";
import InstagramIcon from "../../../assets/images/heirs-icons-instagram.svg";
import TwitterIcon from "../../../assets/images/heirs-icons-twitter.svg";
import LinkedInIcon from "../../../assets/images/heirs-icons-linkedin.svg";
import ndprIcon from "../../../assets/images/ndpr-audit-hil.png";
import { Mixpanel } from "./../../../Mixpanel";

const Index = () => {
  const products = [
    {
      key: "third-party",
      path: "/third-party",
      name: "Third Party Motor",
    },
    /* {
      key: "flexi",
      path: "/flexi-motor",
      name: "Flexi Motor Insurance",
    }, */
    {
      key: "comprehensive",
      path: "/comprehensive-motor",
      name: "Comprehensive Motor",
    },
    {
      key: "her-motor",
      path: "/her-motor",
      name: "Her Motor Insurance",
    },
    {
      key: "burglary",
      path: "/burglary",
      name: "Burglary Insurance",
    },
    /* {
      key: "bikers",
      path: "/bikers",
      name: "Bikers Insurance",
    }, */
    {
      key: "/home-protect",
      path: "/home-protect",
      name: "Homeowner Insurance",
    },
    {
      key: "tenant",
      path: "/tenants",
      name: "Tenants Insurance",
    },
    {
      key: "personal-aacident",
      path: "/personal-accident",
      name: "Personal Accident",
    },
    // {
    //   key: "mobileItems",
    //   path: "HeirsDashboardUrl",
    //   name: "Mobile Items",
    // },
    // {
    //   key: "personalAccident",
    //   path: "/personal-accident",
    //   name: "Personal Accident",
    // },
    // {
    //   key: "goodsInTransit",
    //   path: "/goods-in-transit",
    //   name: "Goods-In-Transit",
    // },
    // {
    //   key: "marine",
    //   path: "HeirsDashboardUrl/marine-goods",
    //   name: "Marine",
    // },
    // {
    //   key: "cargo",
    //   path: "/cargo",
    //   name: "Marine Cargo Insurance",
    // },
    // {
    //   key: "hull",
    //   path: "/hull",
    //   name: "Marine Hull Insurance",
    // },
    // {
    //   key: "Other",
    //   path: "HeirsDashboardUrl",
    //   name: "Other",
    // },
    // {
    //   key: "fire",
    //   path: "/fire",
    //   name: "Fire & Special Perils",
    // },
  ];
  const current_year = new Date().getFullYear();

  return (
    <Container>
      <Wrapper>
        <Row gutter={[150, 0]}>
          <Col lg={10} md={24}>
            <Title>Heirs General Insurance</Title>
            <Text>
              Heirs General Insurance is one of the fastest-growing insurance
              companies with a specialty in non-life insurance, providing simple
              and accessible protection for vehicles, homes, businesses and
              more.
            </Text>

            <Col lg={24} md={0} sm={0} xs={0} style={{ marginTop: "40px" }}>
              <Link to={"/privacy"}>PRIVACY POLICY</Link>
              &nbsp;&nbsp;||&nbsp;&nbsp;
              <Link to={"/terms"}>TERMS & CONDITIONS</Link>
              <Text>
                <a href="/whistleblowing">Whistle Blowing</a>: Call 08113715555{" "}
                <i>whistleblowing@heirsgeneralinsurance.com</i>
              </Text>
            </Col>

            <CompliantWrapper>
              <img src={ndprIcon} alt={"ndpr"} />
              <Text style={{ marginTop: "20px" }}>
                &copy; Copyright {current_year} Heirs General Insurance
              </Text>
            </CompliantWrapper>
          </Col>
          <Col lg={6} md={10}>
            <Title>Quick links</Title>
            <ul>
              {products.map((product) => (
                <li key={product.key}>
                  <a href={product.path} key={product.key}>
                    {product.name}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
          <Col lg={8} md={14}>
            <Title>Follow us</Title>
            <SocialMediaWrapper>
              <a
                href="https://www.facebook.com/heirsgeneralinsurance/"
                target="_blank"
                rel="noreferrer"
                onClick={Mixpanel.track("Socials")}
              >
                <img src={FacebookIcon} alt="facebook" />
              </a>
              <a
                href="https://instagram.com/heirsgeneralinsurance?igshid=YmMyMTA2M2Y=/"
                target="_blank"
                rel="noreferrer"
                onClick={Mixpanel.track("Socials")}
              >
                <img src={InstagramIcon} alt="instagram" />
              </a>
              <a
                href="https://twitter.com/HeirsGeneral/"
                target="_blank"
                rel="noreferrer"
                onClick={Mixpanel.track("Socials")}
              >
                <img src={TwitterIcon} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/heirs-generalinsurance/"
                target="_blank"
                rel="noreferrer"
                onClick={Mixpanel.track("Socials")}
              >
                <img src={LinkedInIcon} alt="linkedin" />
              </a>
            </SocialMediaWrapper>
            <DownloadWrapper>
              <Title>Coming Soon</Title>

              {/* <DownloadItemWrapper>
                <Link to="/">
                  <DownloadItemContent>
                    <AppleFilled />
                  </DownloadItemContent>
                  <DownloadItemContent>
                    <SubTitle>Available on the</SubTitle>
                    <Title>App Store</Title>
                  </DownloadItemContent>
                </Link>
              </DownloadItemWrapper>

              <DownloadItemWrapper>
                <Link to="/">
                  <DownloadItemContent>
                    <IconWrapper>
                      <Icon component={PlayIcon} />
                    </IconWrapper>
                  </DownloadItemContent>
                  <DownloadItemContent>
                    <SubTitle>Get it on</SubTitle>
                    <Title>Google Play</Title>
                  </DownloadItemContent>
                </Link>
              </DownloadItemWrapper> */}
              <img
                src={appleStore}
                alt={"apple"}
                onClick={Mixpanel.track("Mobile App")}
              />
              <img
                src={googlePlay}
                alt={"google"}
                onClick={Mixpanel.track("Mobile App")}
              />
            </DownloadWrapper>
          </Col>
        </Row>

        <Row>
          <Col lg={0}>
            <div style={{ marginTop: "10px" }}>
              <Link to={"/privacy"}>PRIVACY POLICY</Link>
              &nbsp;&nbsp;||&nbsp;&nbsp;
              <Link to={"/terms"}>TERMS & CONDITIONS</Link>
              <Text>
                <a href="/whistleblowing">Whistle Blowing</a>: Call 08113715555{" "}
                <i>whistleblowing@heirsgeneralinsurance.com</i>
              </Text>
              <Text>
                &copy; Copyright {current_year} Heirs General Insurance
              </Text>
            </div>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper style={{ background: "#d6d6d6", width: "100%" }}>
        <Row gutter={[150, 0]}>
          <Col style={{ margin: "0 auto" }}>
            <Text
              style={{
                fontWeight: "bold",
                color: "#000",
                fontSize: "10pt",
              }}
            >
              Authorized and Regulated by the National Insurance Commission, RIC
              NO. 093{" "}
            </Text>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default Index;
