import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
  List,
} from "../PlanPicker/styles";
import HomeownerInsurance from "../../assets/images/Icon_Homeprotect.png";
import { HeirsDashboardUrl } from "../../utils/constants";
import { Mixpanel } from "./../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 690) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="protect">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <img src={HomeownerInsurance} alt="home protect" />
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  <Title>Homeowner Insurance</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Homeowner Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/homeowner-type?policy-type=Home Protect`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Homeowner Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/homeowner-type?policy-type=Home Protect`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
                <th className="ant-table-cell" style={{ width: "33%" }}>
                  {/* <a className="discount">1 Month Free</a> */}
                  <Title>Homeowner Insurance Plus</Title>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Homeowner Insurance - Buy Now")}
                    href={`${HeirsDashboardUrl}/homeowner-type?policy-type=Home Protect Plus`}
                  >
                    Buy Now
                  </a>
                  <a
                    id="purchaseStatus"
                    onClick={Mixpanel.track("Homeowner Insurance - Get Quote")}
                    href={`${HeirsDashboardUrl}/homeowner-type?policy-type=Home Protect Plus`}
                    style={{ marginTop: "5px" }}
                  >
                    Get A Quote
                  </a>
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N25,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the cost of your building</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Perfect for</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Buildings less than N25million</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Buildings above N25million</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Premium Frequency</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Yearly</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Half yearly/ Yearly</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Rebuilds house, if totally damaged by fire</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>For buildings below N25M</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Fully covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Replaces stolen or damaged household fixtures (furniture,
                    etc)
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N2million</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to value of items insured</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Replaces lost personal items</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1million</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to value of items insured</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Replaces desktops, laptops and other related items
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N1M (Inventory required)</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to value of items insured</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Financial support to beneficiary if there is loss of life
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Coverage for others</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Medical bills for injury to the other party in an accident
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N100,000</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Up to N200,000</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="3">
                  <Text highlight>Exclusions (Benefits not covered)</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Mobile phones</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>High value jewellery</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Damage from insect or inherent vice</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cessation from customs or any public authority</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Damage caused by war, terrorism, insurrection, and other
                    related risks
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Not Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell" colspan="2">
                  <Text>
                    <List>
                      <li>Duly Filled Proposal/KYC Form.</li>
                      <li>Photographs of Subject Matter.</li>
                      <li>Evidence of Payment.</li>
                      <li>
                        Valid Means of I.D - Driver License, Voter's Card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Certificate of Incorporation (for company).</li>
                      <li>
                        Proof of residence - Nepa or Lawma bill, Front page of
                        the customer's address (same address as indicated on the
                        form or portal), Local government levy.
                      </li>
                      <li>Valuation Report (Building).</li>
                      <li>Inventory of contents with values.</li>
                      <li>Purchase receipt.</li>
                    </List>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
