import styled from "styled-components";
import { BLACK, WHITE } from "../../utils/colors";

export const TitleText = styled("h1")`
  font-weight: 600;
  font-size: 2.5em;
  line-height: 55px;
  text-align: left;
  color: #ff0000;

  span {
    display: block;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    & {
      font-size: 2.5em;
      line-height: 60px;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 1.7em;
    line-height: 50px;
  }
`;

export const TextContentWrapper = styled("div")`
  @media screen and (min-width: 768px) {
    width: 60%;
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 1.2em;
  }
`;

export const ImageWrapper = styled("div")`
  @media screen and (min-width: 768px) {
    width: 40%;
    display: inline-block;
    vertical-align: top;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const TextParagraph = styled("p")`
  margin-top: 10px;
  font-size: 1.1em;

  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 0.9em;
  }
`;

/*
export const Wrapper = styled("div")`
  text-align: center;
  position: relative;

  background: ${WHITE};
  overflow: hidden;

  ${ButtonWrapper} {
    padding: 20px 45px !important;
    font-size: 25px;
    box-shadow: rgb(241 38 47 / 70%) 1px 10px 17px 1px;

    :hover {
      background: ${BLACK};
    }
  }

  @media screen and (max-width: 768px) {
    ${ButtonWrapper} {
      padding: 10px 25px !important;
    }
  }

  @media screen and (max-width: 480px) {
    ${ButtonWrapper} {
      padding: 10px 25px !important;
    }
  }
`;
*/
