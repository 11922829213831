import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  List,
  PlanChatTitle,
  PlanChatWrapper,
  Text,
  Title,
  Wrapper,
} from "../PlanPicker/styles";
import FireDamages from "../../assets/images/Icon_Homeprotect.png";
// import { HeirsDashboardUrl } from "../../utils/constants";
// import { Mixpanel } from "../../Mixpanel";

const Index = () => {
  const handleChatWithUs = (e) => {
    e.preventDefault();
    window.fcWidget.open({});
  };

  const checkClickEvent = (e) => {
    if (e.target.id === "purchaseStatus") {
      setPurchaseStatus();
    }
  };

  const setPurchaseStatus = () => {
    localStorage.setItem("attempted_purchase", "true");
  };

  const checkScrollTop = () => {
    const tableTop = document.getElementById("d-fixed");
    if (tableTop) {
      if (window.pageYOffset > 890) {
        tableTop.classList.add("p-fixed");
        document.querySelector("#d-fixed .ant-table-tbody").style.display =
          "none";
        document.querySelector(
          "#product-table .ant-table-thead"
        ).style.display = "none";
      } else {
        tableTop.classList.remove("p-fixed");
      }
    }
  };

  useEffect(() => {
    let p = document.getElementById("table");
    let p_prime = p.cloneNode(true);
    document.getElementById("d-fixed").appendChild(p_prime);
  }, []);

  useEffect(() => {
    // localStorage.setItem("last_accessed_product", window.location.pathname);
    window.addEventListener("scroll", checkScrollTop);
    // window.addEventListener("click", checkClickEvent);
  });

  return (
    <Wrapper id="cargo">
      <div className="ant-table" id="ant-table">
        <div id="d-fixed" />
        <div id="product-table">
          <table id="table">
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <img src={FireDamages} alt="home protect" />
                </th>
                <th className="ant-table-cell" style={{ width: "50%" }}>
                  <Title>Marine Hull Insurance</Title>
                  {/* <a id="disabled" href="/">
                    Buy Now
                  </a> */}
                </th>
              </tr>
            </thead>

            <tbody className="ant-table-tbody">
              <tr>
                <td className="ant-table-cell">
                  <Text>For whom?</Text>
                </td>
                <td className="ant-table-cell">
                  <List>
                    <li>
                      Owners of boats, ships, tankers, and other ocean-going
                      vessels
                    </li>
                    <li>For businesses involved in shipment via sea</li>
                  </List>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Cost</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Based on the cost of the vessel</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell" colSpan="2">
                  <Text highlight>Coverage for you</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Replacement of vessel if damaged by fire, accident, or other
                    related water perils
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>
                    Payment for legal issues arising from seepage caused by your
                    damaged vessel.
                  </Text>
                </td>
                <td className="ant-table-cell">
                  <Text>Covered</Text>
                </td>
              </tr>
              <tr>
                <td className="ant-table-cell">
                  <Text>Document Requirements</Text>
                </td>
                <td className="ant-table-cell">
                  <Text>
                    <List>
                      <li>Duly filled proposal form/KYC Form.</li>
                      <li>Certificate of Incorporation (for company).</li>
                      <li>
                        Valid Means of I.D- Driver license, Voter's card, NIMC,
                        National ID, International Passport.
                      </li>
                      <li>Boat/Vessel License.</li>
                      <li>Dry-Docking Certificate.</li>
                      <li>Survey Report.</li>
                      <li>
                        Vessel Classification Certificate where applicable.
                      </li>
                      <li>Vessel Registration Certificate.</li>
                    </List>
                  </Text>
                </td>
              </tr>
              <tr>
                <td colspan="2" style={{ textAlign: "center" }}>
                  <Text>
                    <a
                      href="/contact"
                      style={{
                        background: "#ff0000",
                        color: "#fff",
                        display: "inline-block",
                        padding: "5px 5px 0px",
                        borderRadius: "5px",
                        width: "150px",
                        height: "70px",
                      }}
                    >
                      Contact Us <br />
                      to purchase
                    </a>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <PlanChatWrapper>
        <PlanChatTitle>
          Not Sure of the Right <br />
          Plan for you?
        </PlanChatTitle>
        <Link to="/" onClick={handleChatWithUs}>
          Chat with us
        </Link>
      </PlanChatWrapper>
    </Wrapper>
  );
};

export default Index;
